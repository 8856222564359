import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useNavigate } from 'react-router-dom';

//Assets
import defaultAvatar from 'assets/img/avatar_default.jpg';

//Logic
import { useAuthValue } from 'context/AuthContext';
import { IoBarChartOutline, IoBuildOutline, IoBusinessOutline, IoCalendarNumberOutline, IoCalendarOutline, IoCameraOutline, IoCarOutline, IoCloseOutline, IoConstructOutline, IoCubeOutline, IoGlobeOutline, IoGridOutline, IoHomeOutline, IoLogOutOutline, IoLogoAppleAr, IoPersonOutline, IoReceiptOutline, IoSettingsOutline, IoShareSocialOutline } from 'react-icons/io5';
import { logout } from 'utils/helpers';
import useLocalStorage from 'hooks/useLocalStorage';

function OffcanvasMenu({ show, handleClose }) {

    const { user } = useAuthValue();
    const navigate = useNavigate();
    const [experimentalFeature] = useLocalStorage('experimental_feature');
    const [isDebugActive] = useLocalStorage('is_debug_active');

    return (
        <Offcanvas placement="bottom" className="pb-0" id="main-menu" show={show} onHide={handleClose}>
            <Offcanvas.Header>
                <div className="profile-head cursor-pointer" onClick={() => navigate('/profile')}>
                    <div className="avatar">
                        <img alt="avatar" className="imaged rounded w32" src={user.avatar_url || defaultAvatar} />
                    </div>
                    <div className="in">
                        <h3 className="name m-0 text-16">{user?.display_name}</h3>
                        <h5 className="subtext active">
                            Modifica
                        </h5>
                    </div>
                </div>
                <a className="offcanvas-close me-1" onClick={handleClose}>
                    <IoCloseOutline />
                </a>
            </Offcanvas.Header>
            <Offcanvas.Body className='pt-1'>
                <ul className="listview flush transparent no-line image-listview">
                    <li>
                        <Link to="/" className="item p-0" onClick={handleClose}>
                            <div className="icon-box">
                                <IoGridOutline />
                            </div>
                            <div className="in pe-5">Home</div>
                        </Link>
                    </li>
                    {user.is_agent && <>
                        <li>
                            <Link to="/calendar" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCalendarOutline />
                                </div>
                                <div className="in pe-5">Agenda</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/organizations" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoBusinessOutline />
                                </div>
                                <div className="in pe-5">Attività</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/buildings" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoHomeOutline />
                                </div>
                                <div className="in pe-5">Immobili</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/orders" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCameraOutline />
                                </div>
                                <div className="in pe-5">Storico ordini</div>
                            </Link>
                        </li>
                        {experimentalFeature && <li>
                            <Link to="/orders?type=PLAN" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoLogoAppleAr />
                                </div>
                                <div className="in pe-5">Planimetrie (BETA)</div>
                            </Link>
                        </li>}
                        {experimentalFeature && <li>
                            <Link to="/orders?type=RENDER" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCubeOutline />
                                </div>
                                <div className="in pe-5">Rendering (BETA)</div>
                            </Link>
                        </li>}
                    </>}
                    {(user.is_photographer && !user.is_admin) && <li>
                        <Link to="/post-production-p" className="item p-0" onClick={handleClose}>
                            <div className="icon-box">
                                <IoConstructOutline />
                            </div>
                            <div className="in pe-5">Postproduzione</div>
                        </Link>
                    </li>}
                    {(user.is_admin && user.is_photographer) && <>
                        <li>
                            <Link to="/home/photographer" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCalendarNumberOutline />
                                </div>
                                <div className="in pe-5">Servizi del giorno</div>
                            </Link>
                        </li>
                    </>}
                    {(user.is_photographer && user.is_editor) && <>
                        <li>
                            <Link to="/post-production-e" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCalendarNumberOutline />
                                </div>
                                <div className="in pe-5">Dashboard editor</div>
                            </Link>
                        </li>
                    </>}
                    {user.is_social_manager && <>
                        <li>
                            <Link to="/social-planning" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoShareSocialOutline />
                                </div>
                                <div className="in pe-5">Pianificazione social</div>
                            </Link>
                        </li>
                    </>}
                    {user?.is_admin && <>
                        <li>
                            <Link to="/admin/calendar" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCalendarOutline />
                                </div>
                                <div className="in pe-5">Agenda</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/post-production" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoConstructOutline />
                                </div>
                                <div className="in pe-5">Postproduzione</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/orders" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoReceiptOutline />
                                </div>
                                <div className="in pe-5">Ordini cliente</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/calculate-transfer" className="item p-0" onClick={handleClose}>
                                <div className="icon-box">
                                    <IoCarOutline />
                                </div>
                                <div className="in pe-5">Calcolo trasferte</div>
                            </Link>
                        </li>
                        <li>
                            <Accordion defaultActiveKey="menu_registry">
                                <Accordion.Item eventKey="menu_registry">
                                    <Accordion.Header><span className='fw-600'>Anagrafiche:</span></Accordion.Header>
                                    <Accordion.Body className='ps-0 pt-0'>
                                        <ul className='ps-0'>
                                            <li>
                                                <Link to="/admin/organizations" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoBusinessOutline />
                                                    </div>
                                                    <div className="in pe-5">Attività</div>
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/admin/buildings" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoHomeOutline />
                                                    </div>
                                                    <div className="in pe-5">Immobili</div>
                                                </Link>
                                            </li> */}
                                            <li>
                                                <Link to="/admin/users" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoPersonOutline />
                                                    </div>
                                                    <div className="in pe-5">Utenti</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin/zones" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoGlobeOutline />
                                                    </div>
                                                    <div className="in pe-5">Zone</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin/products" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoCameraOutline />
                                                    </div>
                                                    <div className="in pe-5">Servizi</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </li>
                    </>}
                    {user?.is_admin && !user?.is_accountant && <>
                        <li>
                            <Accordion defaultActiveKey="menu_registry">
                                <Accordion.Item eventKey="menu_registry">
                                    <Accordion.Header><span className='fw-600'>Statistiche:</span></Accordion.Header>
                                    <Accordion.Body className='ps-0 pt-0'>
                                        <ul className='ps-0'>
                                            <li>
                                                <Link to="/stats/editor" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoBusinessOutline />
                                                    </div>
                                                    <div className="in pe-5">Editor giornalieri</div>
                                                </Link>
                                            </li>
                                            {user.is_accountant && user.roles.length === 1 ? '' : <li>
                                                <Link to="/stats/dashboard" className="item p-0" onClick={handleClose}>
                                                    <div className="icon-box">
                                                        <IoBarChartOutline />
                                                    </div>
                                                    <div className="in pe-5">Statistiche</div>
                                                </Link>
                                            </li>}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </li>
                    </>}
                    <li>
                        <a
                            onClick={() => logout()}
                            className="item text-danger p-0 no-after"
                        >
                            <div className="icon-box text-danger">
                                <IoLogOutOutline />
                            </div>
                            <div className="in text-danger">
                                <div>Logout</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </Offcanvas.Body>
        </Offcanvas>
    );

}

export default OffcanvasMenu;
import { useRef } from 'react';

//Fullcalendar
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
//import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
//Components

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import WeekDayNavigator from './WeekDayNavigator';
import { deepCopy, isMobile } from 'utils/helpers';
import { IoAddCircle, IoLockClosed, IoLockClosedOutline, IoTrashOutline } from 'react-icons/io5';
import { Dropdown } from 'react-bootstrap';
import { HoldElement } from './HoldElement';


function DefaultEventContent({ event, view }) {
    if (view.type === 'dayGridMonth') return '';
    const { extendedProps } = event;
    const skeleton = extendedProps.skeleton;
    const eventStartTime = event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //const eventEndTime = event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    if (skeleton) return '';

    return (
        <>
            <div className="fc-event-time" title={extendedProps.info || ''}>
                {eventStartTime} {event.title}
            </div>
            {extendedProps.trashed && <IoTrashOutline className='trashed'/>}
        </>
    );
}

function DefaultResourceHeader({
    info,
    handleClickOptions,
    handleClickDisable
}) {
    const is_mobile = isMobile();
    return (
        <HoldElement 
            className="d-flex cursor-pointer" 
            onClick={is_mobile ? () => handleClickOptions(info) : () => {}}
            // onHold={is_mobile ? () => handleClickAdd(info) : () => {}}
        >
            {!is_mobile && (
                <div className='d-inline-block' style={{ lineHeight: '30px' }}>
                    <IoLockClosed
                        className='me-0 text-20 text-gray'
                        onClick={() => handleClickDisable(info)}
                    />
                </div>
            )}
            <div className='d-inline-block'>
                {info.resource.title}
            </div>
            {!is_mobile && (
                <div className='d-inline-block' style={{ lineHeight: '30px' }}>
                    <IoAddCircle 
                        className='me-0 text-20 text-gray'
                        onClick={() => handleClickOptions(info)}
                    />
                </div>
            )}
        </HoldElement>
    );
}

export default function Calendar({
    logic,
    eventDrop = () => {},
    eventResize = () => {},
    eventClick = () => {},
    EventContent = DefaultEventContent,
    slotMinTime = '00:00:00',
    slotMaxTime = '24:00:00',
    slotDuration = '00:20:00',
    editable = false,
    resources = null,
    leftActionButtons,
    rightActionButtons,
    resourceOptionsClick,
    resourceDisableClick,
    is_admin = false,
}) {

    const {
        ref,
        events,
        viewDate,
        viewTitle,
        handleDayChange,
        navToday, navPrev, navNext,
        isLoading,
        isMonthView
    } = logic;

    const plugins = [timeGridPlugin, bootstrapPlugin, interactionPlugin, dayGridPlugin];

    if (resources) {
        plugins.push(resourceTimeGridPlugin);
        plugins.push(scrollGridPlugin);
    }

    const heightValue = is_admin ? 'calc(100vh - 131px - env(safe-area-inset-top))' : 'calc(100vh - 105px - env(safe-area-inset-top))';

    return (
        <div className={`mb-0 section-full-mobile section`}>
            <div className="card">
                <div className="card-body p-0 placeholder-glow" id="calendar">
                    {<WeekDayNavigator
                        navToday={navToday}
                        viewTitle={viewTitle}
                        navPrev={navPrev}
                        navNext={navNext}
                        activeDate={viewDate}
                        onDayClick={handleDayChange}
                        isLoading={isLoading}
                        isSingleDay={!!resources && !isMonthView}
                        leftActionButtons={leftActionButtons}
                        rightActionButtons={rightActionButtons}
                    />}
                    <FullCalendar
                        ref={ref}
                        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                        plugins={plugins}
                        height='auto'
                        events={events}
                        locale='it'
                        slotMinTime={slotMinTime}
                        slotMaxTime={slotMaxTime}
                        slotDuration={slotDuration}
                        slotLabelInterval="01:00:00"
                        expandRows={true}
                        slotEventOverlap={false}
                        dateClick={info => info.view.type === 'dayGridMonth' && handleDayChange(info.date)}
                        resourceLabelContent={(info) => <DefaultResourceHeader 
                            info={info}
                            handleClickOptions={resourceOptionsClick}
                            handleClickDisable={resourceDisableClick}
                        />}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: true,
                            meridiem: 'short'
                        }}
                        allDaySlot={false}
                        headerToolbar={false}
                        eventColor='#1797589c'
                        eventTextColor='#FFFFFF'
                        themeSystem='bootstrap'
                        eventClassNames={info => {
                            let eventClass = '';
                            if (info.event.extendedProps.not_done) {
                                eventClass = 'ev-not-done ';
                            }
                            if ( info.event.extendedProps.disabled ) return eventClass + 'ev-disabled';
                            if ( info.event.extendedProps.skeleton ) return eventClass + 'placeholder';
                            if ( !info.event.durationEditable ) return eventClass + 'ev-opacity';
                            return '';
                        }}
                        editable={editable}
                        eventDrop={eventDrop}
                        eventResize={eventResize}
                        eventClick={eventClick}
                        eventOverlap={false}
                        eventContent={EventContent}
                        eventMinHeight="10"
                        firstDay="1"
                        {...resources && !isMonthView && { 
                            resources,
                            initialView: "resourceTimeGridDay",
                            scrollTime: "08:00:00",
                            dayMinWidth: isMobile() ? ((window.innerWidth - 40) / 3) : 200,
                            height: heightValue,
                            resourceOrder: 'show_priority'
                        }}
                        {...!resources && { 
                            views: {
                                threeDay: {
                                    type: 'timeGrid',
                                    duration: { days: 3 },
                                    buttonText: '3 giorni'
                                },
                                sevenDay: {
                                    type: 'timeGrid',
                                    duration: { days: 7 },
                                    buttonText: '7 giorni'
                                },
                            },
                            initialView: isMobile() ? 'threeDay' : 'sevenDay',
                            height: heightValue,
                            // hiddenDays: [0]
                         }}
                         stickyHeaderDates={true}
                    />
                </div>
            </div>
        </div>
    );
}
import { ProgressBar } from "react-bootstrap";


export default function ProgressGraph({
    data
}) {

    return (
        <div>
            <ProgressBar>
                {data.map(d => (
                    <ProgressBar now={d.value}  key={d.label}  style={{ backgroundColor: d.color}}  />
                ))}
            </ProgressBar>
            <div className="d-flex justify-content-between mt-2">
                {data.map(d => (
                    <div className="text-center" key={d.label}>
                        <div className="text-20" style={{ color: d.color}}>{d.value}%</div>
                        <div className="text-12">{d.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffCanvasCloseBtn from 'components/offcanvas/OffCanvasCloseBtn';

//Components
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoAdd, IoCheckmark, IoCloudUploadOutline, IoPricetag, IoTrash } from 'react-icons/io5';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import OrganizationPriceMatrix from './OrganizationPriceMatrix';
import StripeCredit from 'components/StripeCredit';
import OffCanvasLoader from 'components/loader/OffCanvasLoader';
import { toast } from 'react-toastify';

function useOffCanvasManageOrganization(props) {
    const {
        organizationId
    } = props;

    const [organizationDetail, setOrganizationDetail] = useState();
    const [products, setProducts] = useState();
    const [subscriptions, setSubscriptions] = useState();
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);

    useEffect(() => {
        if (!organizationId) {
            setProducts();
            setIsConfirmDelete(false);
            return setOrganizationDetail();
        }

        if (!subscriptions) {
            axios.get('products', { params: { type: 'CREDIT' }})
            .then(res => setSubscriptions(res.data));
        }

        Promise.all([
            axios.get(`/organizations/${organizationId}`),
            axios.get('products', { params: { organization_id: organizationId } }),
        ])
        .then(([resOrgDetail, resProducts]) => {
            setOrganizationDetail(resOrgDetail.data);
            setProducts(resProducts.data);
        });
        
    }, [organizationId]);

    const setPriceMatrix = (product_id, price_matrix) => {
        setProducts(products.map(product => {
            if (product.id !== product_id) return product;
            product.needSave = true;
            product.price_matrix = price_matrix;
            return product;
        }));
    }

    const handleSavePriceMatrix = (e, product_id) => {
        e.preventDefault();
        const price_matrix = products.find(p => p.id === product_id).price_matrix;
        axios.post('products-organizations', {
            product_id,
            organization_id: organizationId,
            price_matrix: price_matrix
        })
        .then(res => {
            setProducts(products.map(product => {
                if (res.data.id !== product.id) return product;
                return {...product, needSave: false, ...res.data};
            }));
        });
    }

    const handleResetPriceMatrix = (product_id) => {
        axios.delete('products-organizations', {
            data: {
                product_id,
                organization_id: organizationId,
            }
        })
        .then(res => {
            setProducts(products.map(product => {
                if (res.data.id !== product.id) return product;
                return {...product, needSave: false, ...res.data};
            }));
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        axios.put(`/organizations/${organizationDetail.id}`, {
            [name]: value,
        })
        .then(res => {
            setOrganizationDetail(res.data);
        });
    }

    const handleDelete = () => {
        if (!isConfirmDelete) return setIsConfirmDelete(true);
        axios.delete(`/organizations/${organizationDetail.id}`)
        .then(() => {
            toast.success('Attività eliminata con successo');
            setTimeout(() => window.location.reload(), 800);
        })
    }

    return {
        organizationDetail,
        products,
        subscriptions,
        setPriceMatrix,
        handleSavePriceMatrix,
        handleResetPriceMatrix,
        handleChange,
        isConfirmDelete,
        handleDelete
    }
}

export default function OffCanvasManageOrganization(props) {

    const {
        organizationId,
        handleClose
    } = props;

    const {
        organizationDetail,
        products,
        subscriptions,
        setPriceMatrix,
        handleSavePriceMatrix,
        handleResetPriceMatrix,
        handleChange,
        isConfirmDelete,
        handleDelete
    } = useOffCanvasManageOrganization(props);

    return (<>
        <Offcanvas placement="bottom" className="action-sheet" show={!!organizationId} onHide={handleClose}>
            <Offcanvas.Body>
                <div className="section mt-3 text-center">
                    <h3>{organizationDetail?.display_name}</h3>
                </div>
                {/*<a 
                    className="close-btn-modal text-25 back-btn-modal text-danger"
                    onClick={() => handleDelete(isConfirmDelete)}
                >
                    {isConfirmDelete ? <IoCheckmark /> : <IoTrash />}
                </a>*/}
                {(!organizationDetail || !products ) && <OffCanvasLoader />}
                <OffCanvasCloseBtn handleClose={handleClose}/>

                <div className='section py-2'>
                    <Tabs
                        defaultActiveKey="settings"
                        id="uncontrolled-tab-example"
                        className="mb-1 capsuled"
                    >
                        <Tab eventKey="settings" title="Impostazioni">
                            <Form className="section px-0 py-2" onSubmit={() => {}}>
                                <div className="form-group boxed custom">
                                    <div className="input-wrapper">
                                        <Form.Label>Abbonamento</Form.Label>
                                        <Form.Select 
                                            className='form-control'
                                            value={organizationDetail?.subscription_id || ''}
                                            onChange={handleChange}
                                            name="subscription_id"
                                        >
                                            <option value="">NESSUNO</option>
                                            {subscriptions && subscriptions.map(product => (
                                                <option key={product.id} value={product.subscription.id}>{product.description}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </div>
                            </Form>
                        </Tab>

                        <Tab eventKey="stripe" title="Credito">
                            <StripeCredit
                                organizationId={organizationId}
                            />
                        </Tab>

                        <Tab eventKey="prices" title="Tariffe">
                            
                            <Accordion>
                                {products && products.filter(p => ['PRIMARY', 'EXTRA'].includes(p.type)).map(product => (
                                    <Accordion.Item eventKey={product.id} key={product.id}>
                                        <Accordion.Header>
                                            {product.description}
                                            {!!product.custom_price && <IoPricetag className='text-success text-16 mx-1'/> }
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <OrganizationPriceMatrix 
                                                product={product}
                                                priceMatrix={product.price_matrix}
                                                setPriceMatrix={setPriceMatrix}
                                                handleSave={handleSavePriceMatrix}
                                            />
                                            {product.custom_price && <div className="form-group basic">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-danger w-100"
                                                    onClick={() => handleResetPriceMatrix(product.id)}
                                                >Ripristina listino</button>
                                            </div>}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Tab>
                    </Tabs>
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    </>);

}
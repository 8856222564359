//Components
import Capsule from 'layout/Capsule';
import OffCanvasViewAppointment from 'components/offcanvas/view-appointment/OffCanvasViewAppointment';
import Calendar from 'components/Calendar';

//Logic
import useAdminCalendar from './useAdminCalendar';

//Utils
import Header from 'layout/header/Header';
import OffcanvasSwitchSlots from './components/OffcanvasSwitchSlots';
import OffCanvasDisableSlots from './components/OffCanvasDisableSlots';
import OffcanvasAddManualAppointment from './components/OffcanvasAddManualAppointment';
import OffCanvasAddOrgAppointment from './components/OffCanvasAddOrgAppointment';
import OffCanvasSettings from './components/OffCanvasSettings';
import { IoAdd, IoCalendarOutline, IoMenuOutline, IoSettingsOutline } from 'react-icons/io5';
import OffcanvasAddBuilding from 'components/offcanvas/add-building/OffcanvasAddBuilding';
import OffCanvasResourceSelected from './components/OffCanvasResourceSelected';
import { useReducer } from 'react';
import OffcanvasMenu from 'components/offcanvas/OffcanvasMenu';


export default function AdminCalendar() {

  const {
    handleEventClick,
    resources,
    selectedAppointmentId, dispatchViewAppointment,
    showSwitchPhotographers, dispatchShowSwitchPhotographers,
    showDisableSlots, dispatchShowDisableSlots,
    showAddOrgAppointment, dispatchShowAddOrgAppointment,
    showNewBuilding, dispatchShowNewBuilding,
    showOffCanvasSettings, dispatchShowOffCanvasSettings,
    showAddManualAppointment, dispatchShowAddManualAppointment,
    showResourceSelected, dispatchShowResourceSelected,
    resourceInfoSelected, handleSelectResourceInfo,
    handleSelectOrganization,
    organizationSelected,
    handleEventUpdate,
    calendarLogic,
  } = useAdminCalendar();

  const [showMenu, dispatchShowMenu] = useReducer((showMenu, action) => {
      return action === 'CLOSE' ? false : true;
  });


  return (
    <>
      <Capsule className="pb-0 mt-0 pt-1">
          <Calendar 
            eventDrop={handleEventUpdate}
            eventResize={handleEventUpdate}
            eventClick={handleEventClick}
            editable={true}
            is_admin={true}
            resources={resources || []}
            logic={calendarLogic}
            leftActionButtons={[
              { handleClick: () => dispatchShowMenu('OPEN'), icon: <IoMenuOutline className='me-0'/>, label: 'Menu' },
              { handleClick: () => dispatchShowSwitchPhotographers(true) , label: 'Scambia' },
              { handleClick: () => calendarLogic.changeView('dayGridMonth') , icon: <IoCalendarOutline className='me-0'/>, label: 'Mese'},
              // { handleClick: () => dispatchShowAddOrgAppointment('OPEN') , label: <IoAdd className='me-0'/> }
            ]}
            rightActionButtons={[
              { handleClick: () => dispatchShowOffCanvasSettings(true), label: <IoSettingsOutline className='me-0'/> },
            ]}
            resourceOptionsClick={(info) => {
              handleSelectResourceInfo(info);
              dispatchShowResourceSelected('OPEN')
            }}
            resourceDisableClick={(info) => {
              handleSelectResourceInfo(info);
              dispatchShowDisableSlots('OPEN')
            }}
          />
      </Capsule>
      <OffCanvasViewAppointment 
          appointmentId={selectedAppointmentId}
          handleClose={() => dispatchViewAppointment('CLOSE')}
          photographers={resources}
      />
      <OffcanvasSwitchSlots
        show={showSwitchPhotographers}
        handleClose={() => dispatchShowSwitchPhotographers('CLOSE')}
        photographers={resources}
        switchDate={calendarLogic?.viewDate}
      />
      <OffCanvasDisableSlots
        resourceInfo={resourceInfoSelected}
        show={showDisableSlots}
        handleClose={() => dispatchShowDisableSlots('CLOSE')}
        disableDate={calendarLogic?.viewDate}
      />
      <OffCanvasAddOrgAppointment
        show={showAddOrgAppointment}
        handleClose={() => dispatchShowAddOrgAppointment('CLOSE')}
        handleSelectOrganization={handleSelectOrganization}
        handleNewBuilding={() => dispatchShowNewBuilding('OPEN')}
        organizationSelected={organizationSelected}
        resourceInfo={resourceInfoSelected}
      />
      <OffcanvasAddManualAppointment
        resourceInfo={resourceInfoSelected}
        show={showAddManualAppointment}
        handleClose={() => dispatchShowAddManualAppointment('CLOSE')}
      />
      <OffCanvasResourceSelected
          show={!!showResourceSelected}
          resourceInfo={resourceInfoSelected}
          handleClose={() => dispatchShowResourceSelected('CLOSE')}
          handleSelectManual={() => dispatchShowAddManualAppointment('OPEN')}
          handleSelectClient={() => dispatchShowAddOrgAppointment('OPEN')}
          handleSelectLock={() => dispatchShowDisableSlots('OPEN')}
      />
      <OffcanvasAddBuilding
          show={!!organizationSelected && showNewBuilding}
          handleClose={() => dispatchShowNewBuilding('CLOSE')}
          organization={organizationSelected}
          isReservation
      />
      <OffCanvasSettings
        show={showOffCanvasSettings}
        photographers={resources}
        handleClose={() => dispatchShowOffCanvasSettings('CLOSE')}
      />
      <OffcanvasMenu 
          show={showMenu}
          handleClose={() => dispatchShowMenu('CLOSE')}
      />
  </>);
}

import Capsule from "layout/Capsule";
import Header from "layout/header/Header";

import { AreaChart, BarChart, LineChart, Sparkline } from '@saas-ui/charts'
import { Card, Pagination } from "react-bootstrap";
import { SaasProvider } from '@saas-ui/react'
import ProgressGraph from "components/graphs/StepperGraph";
import { CardGraph } from "components/graphs/CardGraph";
import GoalGraph from "components/graphs/GoalGraph";
import AdvanceDataTable from "components/AdvanceDataTable";
import { AutocompleteOrganization } from "components/autocomplete/Autocomplete";
import FilterBox from "components/FilterBox";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { formatterEuro } from "utils/helpers";
import { appShowLoader } from "store/actions/appLoader";
import { useDispatch } from "react-redux";

const data = {
    total_sales: 0,
    total_product_sales: 0,
    total_services: 0,
    total_priority: 0,
    average_order: 0,
    average_sqm: 0,
    sales:[
        {
            date: 'Gen',
            '2024': 460000,
            '2023': 210324,
          },
          {
            date: 'Feb',
            '2024': 380324,
            '2023': 220324,
          },
          {
            date: 'Mar',
            '2024': 490324,
            '2023': 220324,
          },
          {
            date: 'Apr',
            '2024': 480324,
            '2023': 290324,
          },
          {
            date: 'Mag',
            '2024': 500324,
            '2023': 220324,
          },
          {
            date: 'Giu',
            '2024': 420324,
            '2023': 300324,
          },
          {
            date: 'Lug',
            '2024': 410324,
            '2023': 280324,
          },
          {
            date: 'Ago',
            '2024': 440324,
            '2023': 230324,
          },
          {
            date: 'Set',
            '2024': 440324,
            '2023': 240324,
          },
          {
            date: 'Ott',
            '2024': 440324,
            '2023': 300324,
          },
          {
            date: 'Nov',
            '2024': 460324,
            '2023': 250324,
          },
          {
            date: 'Dic',
            '2024': 480324,
            '2023': 250324,
          },    
        ],
    trend: [
        {
          date: 'Dec 1',
          'Ordini': 40,
          'Servizi': 21,
        },
        {
          date: 'Dec 2',
          'Ordini': 38,
          'Servizi': 22,
        },
        {
          date: 'Dec 3',
          'Ordini': 49,
          'Servizi': 22,
        },
        {
          date: 'Dec 4',
          'Ordini': 48,
          'Servizi': 29,
        },
        {
          date: 'Dec 5',
          'Ordini': 50,
          'Servizi': 22,
        },
        {
          date: 'Dec 6',
          'Ordini': 42,
          'Servizi': 30,
        },
        {
          date: 'Dec 7',
          'Ordini': 41,
          'Servizi': 28,
        },
        {
          date: 'Dec 8',
          'Ordini': 44,
          'Servizi': 23,
        },
        {
          date: 'Dec 9',
          'Ordini': 44,
          'Servizi': 24,
        },
        {
          date: 'Dec 10',
          'Ordini': 44,
          'Servizi': 30,
        },
        {
          date: 'Dec 11',
          'Ordini': 46,
          'Servizi': 25,
        },
        {
          date: 'Dec 12',
          'Ordini': 48,
          'Servizi': 25,
        },
        {
          date: 'Dec 13',
          'Ordini': 46,
          'Servizi': 25,
        },
        {
          date: 'Dec 14',
          'Ordini': 50,
          'Servizi': 28,
        },
        {
          date: 'Dec 15',
          'Ordini': 42,
          'Servizi': 34,
        },
        {
          date: 'Dec 16',
          'Ordini': 58,
          'Servizi': 33,
        },
        {
          date: 'Dec 17',
          'Ordini': 49,
          'Servizi': 35,
        },
        {
          date: 'Dec 18',
          'Ordini': 44,
          'Servizi': 33,
        },
        {
          date: 'Dec 19',
          'Ordini': 46,
          'Servizi': 35,
        },
        {
          date: 'Dec 20',
          'Ordini': 44,
          'Servizi': 35,
        },
        {
          date: 'Dec 21',
          'Ordini': 51,
          'Servizi': 30,
        },
        {
          date: 'Dec 22',
          'Ordini': 58,
          'Servizi': 36,
        },
        {
          date: 'Dec 23',
          'Ordini': 46,
          'Servizi': 30,
        },
        {
          date: 'Dec 24',
          'Ordini': 61,
          'Servizi': 30,
        },
        {
          date: 'Dec 25',
          'Ordini': 56,
          'Servizi': 33,
        },
        {
          date: 'Dec 26',
          'Ordini': 55,
          'Servizi': 33,
        },
        {
          date: 'Dec 27',
          'Ordini': 47,
          'Servizi': 32,
        },
        {
          date: 'Dec 28',
          'Ordini': 55,
          'Servizi': 33,
        },
        {
          date: 'Dec 29',
          'Ordini': 61,
          'Servizi': 32,
        },
        {
          date: 'Dec 30',
          'Ordini': 62,
          'Servizi': 29,
        },
        {
          date: 'Dec 31',
          'Ordini': 52,
          'Servizi': 37,
        },
    ],
    best_service: [
        {
            label: 'Foto',
            value: 30,
            color: '#135CFA'
        },
        {
            label: 'Video',
            value: 35,
            color: '#13B9FA'
        },
        {
            label: 'Render',
            value: 25,
            color: '#3BE7F9'
        },
        {
            label: 'Drone',
            value: 10,
            color: '#C5F4F7'
        },
        {
            label: 'Matteport',
            value: 10,
            color: '#C5F4F7'
        }
    ],
    building_state: [
        {
            label: 'Ordinati',
            value: 65,
            color: '#135CFA'
        },
        {
            label: 'Disordinati',
            value: 45,
            color: '#13B9FA'
        },
    ],
    survey: [
        {
            label: '212 svolti',
            value: 91,
            color: '#135CFA'
        },
        {
            label: '13 non svolti',
            value: 9,
            color: '#13B9FA'
        },
    ],
    building_types: [
        {
            label: 'Appartamento',
            value: 45,
            color: '#135CFA'
        },
        {
            label: 'Villa',
            value: 25,
            color: '#13B9FA'
        },
        {
            label: 'Appartamento con giardino',
            value: 30,
            color: '#3BE7F9'
        }
    ],
    postproduction: [
        {
            type: 'Foto',
            Fotografo: 2,
            Editor: 26,
        },
        {
            type: 'Video',
            Fotografo: 3,
            Editor: 28,
        },
        {
            type: 'Plan',
            Editor: 47,
        },
        {
            type: 'Render',
            Editor: 56,
        },
    ],
    products: [
        { name: 'Servizio fotografico', sales: 2133, count: 209 },
        { name: 'Drone', sales: 212, count: 244 },
        { name: 'Foto 360', sales: 124, count: 322 },
        { name: 'Matterport', sales: 4214, count: 34 },
        { name: 'Virtual tour', sales: 233, count: 56 },
    ],
    customers: [
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
        { name: 'V430 Digital', sales: 2133, count: 209, average: 150},
    ],
    photographers: [
        { name: 'Angelo Daupi', quantity: 30, sales: 2133, cost: 2324 },
        { name: 'Angelo Daupi', quantity: 30, sales: 2133, cost: 2324 },
        { name: 'Angelo Daupi', quantity: 30, sales: 2133, cost: 2324 },
        { name: 'Angelo Daupi', quantity: 30, sales: 2133, cost: 2324 },
        { name: 'Mattia Desiderio', quantity: 30, sales: 2133, cost: 2324 },
        { name: 'Andrea Bellei', quantity: 30, sales: 2133, cost: 2324 },
    ],
    km_refounds: [
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
        { name: 'Angelo Daupi', km_business_car: 2133, km_own_car: 2324, km_total: 334255},
    ]
}

export default function StatsDashboard() {
    
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
        photographer_id: "",
        editor_id: "",
        product_id: "",
        organization_id: ""
    });
    const [statsData, setStatsData] = useState(data);
    const formatterHour = (value) => `${value}h`;
    const formatterKm = (value) => `${value} km`;
    const totalTableRow = (props, field, euro = true) => {
        const total = props.table.getRowModel().rows.reduce((acc, row) => (acc + row.original[field]), 0);
        return euro ? formatterEuro(total) : total;
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        if (['from', 'to'].includes(name) && !value) return;
        setFilter({
            ...filter,
            [name]: value
        });
    }
    

    // useEffect(() => {
    //     axios.get('stats/yearly', { params: filter })
    // }, [filter]);

    useEffect(() => {
        // axios.get('/stats/dashboard')
        // .then(res => {
        //     setStatsData(res.data)
        // });
        dispatch(appShowLoader(true));
    }, []);

    
    return (
        <>
            <Header
                show_agency={false}
                pageTitle="Statistiche" 
            />
            <Capsule className="stats">
                <SaasProvider>
                    <FilterBox 
                        filter={filter}
                        handleChangeFilter={handleChangeFilter}
                    />
                    <div className="section d-flex gap-2 flex-column">
                        {/* <Slider 
                                infinite={false}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                variableWidth={false}
                                arrows={false}
                                dots={true}
                        >
                        </Slider> */}
                        {/* <Card>
                            <Card.Body className="py-3">
                                <h3>Fatturato annuale</h3>
                                <Stat pos="relative">
                                    <StatHelpText pos="absolute" top="0" right="0">
                                        <StatArrow type="increase" /> {10}%
                                    </StatHelpText>
                                    <StatNumber>{(32553242).toLocaleString('it-IT')}€</StatNumber>
                                    <Sparkline
                                        data={data.sales}
                                        categories={['2023', '2024']}
                                        colors={['#7D7F82', '#135CFA']}
                                        height="60px"
                                        mx="-4"
                                    />
                                </Stat>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="py-3">
                                <h3>Servizi mensili</h3>
                                <Stat pos="relative">
                                    <StatHelpText pos="absolute" top="0" right="0">
                                        <StatArrow type="increase" /> {12}%
                                    </StatHelpText>
                                    <StatNumber>{(32553242).toLocaleString('it-IT')}€</StatNumber>
                                    <Sparkline
                                        data={data.sales}
                                        categories={['2023', '2024']}
                                        colors={['#7D7F82', '#135CFA']}
                                        height="60px"
                                        mx="-4"
                                    />
                                </Stat>
                            </Card.Body>
                        </Card> */}
                        <Card>
                            <Card.Body className="py-3">
                                <h3>% Fatturato servizi</h3>
                                <ProgressGraph 
                                    data={data.best_service}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="py-3">
                                <h3>Stato immobili</h3>
                                <ProgressGraph 
                                    data={data.building_state}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="py-3">
                                <h3>Tipologie immobili</h3>
                                <ProgressGraph 
                                    data={data.building_types}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="py-3">
                                <h3>Questionario</h3>
                                <ProgressGraph 
                                    data={data.survey}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="px-0 py-3">
                                <div className="section">
                                    <h3>Tempi post-produzione</h3>
                                </div>
                                <BarChart
                                    index="type"
                                    data={data.postproduction}
                                    categories={['Fotografo', 'Editor']}
                                    colors={['#3BE7F9', '#13B9FA']}
                                    valueFormatter={formatterHour}
                                    height="300px"
                                    stack
                                />
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Body className="p-0">
                                <div className="section pt-2 pb-1">
                                    <h3>Prodotti</h3>
                                </div>
                                <AdvanceDataTable 
                                    columns={[
                                        {
                                            accessorKey: 'name',
                                            header: 'Prodotto',
                                            cell: info => info.getValue(),
                                            footer: 'Totale'
                                        },
                                        {
                                            accessorKey: 'count',
                                            header: 'N. servizi',
                                            cell: info => info.getValue(),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'count', false)
                                        },
                                        {
                                            accessorKey: 'sales',
                                            header: 'Fatturato',
                                            cell: info => formatterEuro(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'sales')
                                        }
                                    ]}
                                    data={data.products}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="p-0">
                                <div className="section pt-2 pb-1">
                                    <h3>Clienti</h3>
                                </div>
                                <AdvanceDataTable 
                                    columns={[
                                        {
                                            accessorKey: 'name',
                                            header: 'Nome',
                                            cell: info => info.getValue(),
                                            footer: 'Totale'
                                        },
                                        {
                                            accessorKey: 'count',
                                            header: 'N. servizi',
                                            cell: info =>info.getValue(),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'count', false)
                                        },
                                        {
                                            accessorKey: 'sales',
                                            header: 'Fatturato',
                                            cell: info => formatterEuro(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'sales')
                                        },
                                        {
                                            accessorKey: 'average',
                                            header: 'Media ordine',
                                            cell: info => formatterEuro(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'average')
                                        }
                                    ]}
                                    data={data.customers}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="p-0">
                                <div className="section pt-2 pb-1">
                                    <h3>Fotografi</h3>
                                </div>
                                <AdvanceDataTable 
                                    columns={[
                                        {
                                            accessorKey: 'name',
                                            header: 'Nome',
                                            cell: info => info.getValue(),
                                            footer: 'Totale'
                                        },
                                        {
                                            accessorKey: 'quantity',
                                            header: 'N. servizi',
                                            cell: info => info.getValue(),
                                            footer: (props) => totalTableRow(props, 'quantity', false)
                                        },
                                        {
                                            accessorKey: 'sales',
                                            header: 'Prodotto',
                                            cell: info => formatterEuro(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'sales')
                                        },
                                        {
                                            accessorKey: 'cost',
                                            header: 'Costo',
                                            cell: info => formatterEuro(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'cost')
                                            
                                        },
                                    ]}
                                    data={data.photographers}
                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body className="p-0">
                                <div className="section pt-2 pb-1">
                                    <h3>Rimborsi KM</h3>
                                </div>
                                <AdvanceDataTable 
                                    columns={[
                                        {
                                            accessorKey: 'name',
                                            header: 'Nome',
                                            cell: info => info.getValue(),
                                            footer: 'Totale'
                                        },
                                        {
                                            accessorKey: 'km_business_car',
                                            header: 'KM Auto aziendale',
                                            cell: info => formatterKm(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'km_business_car')
                                        },
                                        {
                                            accessorKey: 'km_own_car',
                                            header: 'Km auto personale',
                                            cell: info => formatterKm(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'km_own_car')
                                        },
                                        {
                                            accessorKey: 'km_total',
                                            header: 'Km totali',
                                            cell: info => formatterKm(info.getValue()),
                                            meta: {
                                                filterVariant: 'range',
                                            },
                                            footer: (props) => totalTableRow(props, 'km_total')
                                        },
                                    ]}
                                    data={data.km_refounds}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </SaasProvider>
            </Capsule>
        </>
    );
}
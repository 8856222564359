//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components

//Assets
import { IoCloseCircleOutline, IoCloseOutline, IoCloudUploadOutline, IoDocument, IoDownload, IoInformationCircle, IoOpenOutline, IoPencil, IoPricetag, IoPricetags, IoRemoveOutline, IoTime } from 'react-icons/io5';

//Logic
import usePlan from './usePlan';
import { Offcanvas, ProgressBar } from 'react-bootstrap';
import OffcanvasServiceInfo from 'components/offcanvas/OffCanvasProductServiceInfo';
import SkeletonDiv from 'components/loader/SkeletonDiv';
import Checkout from 'components/Checkout';
import useOrder from 'hooks/useOrder';
import ModalConfirm from 'components/ModalConfirm';
import InputUpload from 'components/InputUpload';

function ProductLine({
    isSkeleton,
    product,
    onServiceInfo,
    onSelect = () => { }
}) {
    
    return (
        <div className="form-check">
            <input 
                type="radio" 
                className="form-check-input" 
                onChange={() => onSelect(product.id)}
                checked={!!product?.is_selected}
                id={`product_${product?.id}`}
            />
            <label className="form-check-label" htmlFor={`product_${product?.id}`}>
                <div className="flex-fill">
                    <div className="service-title service-title d-flex justify-content-between">
                        <span>{isSkeleton ? <SkeletonDiv /> : product.description}</span>
                        {isSkeleton ? <SkeletonDiv w="50px"/> : <>
                            <span className="d-inline-block active text-14 text-end align-self-baseline nowrap">
                                <IoInformationCircle 
                                    className="info-icon float-start" 
                                    onClick={() => onServiceInfo(product.id)}
                                />
                                {product?.price_matrix.price}€
                            </span>
                        </>}
                    </div>
                </div>
            </label>
        </div>
    );
}

function Plan() {

    const {
        products,
        handleSelectProduct,
        showOffCanvasServiceInfo, setShowOffCanvasServiceInfo,
        cartItems,
        handleInputUpload,
        removeFile,
        instructions, handleChangeInstructions,
        placeOrder,
        isInProgress,
        isCartInProgress,
        handlePlaceOrder,
        completedOrderData,
        handleCloseModalConfirm,
        plan
    } = usePlan();

    return (
        <>
            <Header
                pageTitle={'Planimetria 2D/3D'}
                handleBack="home"
            />
            <Capsule>

                <div style={{
                    maxHeight: '200px',
                    overflow: 'hidden',
                    backgroundColor: '#f6f6f6',
                    backgroundImage: 'url(https://i.imgur.com/7iLF1Rj.jpeg)',
                    height: '300px',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}
                ></div>


                <div className="header-large-title mt-3">
                    <h2 className="title lh-1">Richiedi una planimetria 2D o 3D</h2>
                </div>

                <div className="section rounded mt-2">
                    <h4 className="card-subtitle text-white pt-1 text-12">Seleziona la tipologia:</h4>

                    <div className="wide-block p-0 rounded">
                        <div className="input-list service-items">
                            {products ? products.map(product => (
                                <ProductLine 
                                    key={product.id} 
                                    product={product}
                                    onSelect={handleSelectProduct}
                                    onServiceInfo={setShowOffCanvasServiceInfo}
                                />
                            )) : Array(2).fill(null).map((_, i) => <ProductLine key={i} isSkeleton />)}
                        </div>
                    </div>

                </div>
                
                {cartItems?.products.length ? <>
                    <div className="section mt-2">
                        <p>
                        Carica la planimetria in JPG/JPEG o in PDF, assicurati che ci sia una misura di una parete e che ci sia la disposizione dell’arredamento.
                        </p>
                    </div>

                    <div className='section mt-3'>
                        <h4 className="card-subtitle text-white text-12">Carica la planimetria da elaborare:</h4>
                        
                        <InputUpload
                            gkey="plan"
                            accept=".png, .jpg, .jpeg, .heic, .pdf"
                            limit={1}
                        />
                    </div>

                    <div className="section mt-3">
                        <h4 className="card-subtitle text-white text-12">NOTE AGGIUNTIVE:</h4>
                        <div className="form-group boxed custom pt-0">
                            <div className="input-wrapper">
                                <textarea 
                                    rows="3" 
                                    className="form-control bg-amazon"
                                    placeholder="Scrivi qui il tipo di pavimento da utilizzare o altre note" 
                                    value={instructions}
                                    onChange={handleChangeInstructions}
                                />
                            </div>
                        </div>
                    </div>

                    <Checkout 
                        cartItems={cartItems}
                        isInProgress={isInProgress || !instructions || !cartItems?.products.length || !Object.keys(plan).length}
                        isCartInProgress={isCartInProgress}
                        completeCheckout={handlePlaceOrder}
                    />
                </> : ''}
            </Capsule>
            <OffcanvasServiceInfo
                product={showOffCanvasServiceInfo}
                handleClose={() => setShowOffCanvasServiceInfo('CLOSE')}
            />
            <ModalConfirm 
                show={!!completedOrderData}
                handleClose={() => handleCloseModalConfirm()}
            />
        </>

    );
}

export default Plan;
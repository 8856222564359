import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from 'context/AuthContext';
import axios from "axios";
import { loadLaravelUser } from "utils/helpers";

function useWelcomeAgent() {

    const [showOffCanvasAdd, setShowOffCanvasAdd] = useState(false);
    const { user, setUser } = useAuthValue();
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.agent?.organizations?.length) navigate('/');
    }, [user]);

    const handleConfirmInvite = id => {
        axios.put(`organization-pending-agents/${id}/action/accept`)
        .then(handleReloadUser)
    }

    const handleReloadUser = () => {
        loadLaravelUser()
        .then(user => setUser(user));
    }

    return {
        showOffCanvasAdd, setShowOffCanvasAdd,
        handleConfirmInvite, handleReloadUser
    }
}

export default useWelcomeAgent;
import Offcanvas from 'react-bootstrap/Offcanvas';

//Assets
import defaultAvatar from 'assets/img/avatar_default.jpg';
import { IoCall, IoCamera, IoCash, IoCloseOutline, IoCopy, IoExpandOutline, IoHome, IoHourglass, IoInformationCircle, IoLeafOutline, IoLocation, IoMail, IoPencil, IoPerson, IoRefreshOutline, IoTime, IoTrashOutline } from 'react-icons/io5';

//Logic
import useOffCanvasViewAppointment from './useOffCanvasViewAppointment';

//Utils
import moment from 'moment';
import { addressUrl, capitalizeFirstLetter } from 'utils/helpers';
import { Fragment } from 'react';
import { useAuthValue } from 'context/AuthContext';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import AutocompleteAddress from 'components/autocomplete/AutocompleteAddress';
import { PAYMENT_METHODS } from 'constants';
import { toast } from 'react-toastify';

function OffcanvasViewAppointment(props) {

    const {
        appointmentId,
        handleClose,
        photographers = []
    } = props;

    const {
        headerImage,
        appointmentData,
        dispatchConfirmDelete,
        handleChange,
        needSave,
        handleUpdate,
        address, setAddress,
        handleRetriveAddress,
        handleResetAddress,
        canEdit = true,
        handleUnlockAppointment
    } = useOffCanvasViewAppointment(props);

    const { user } = useAuthValue();
    const navigate = useNavigate();

    const isEditable = appointmentData?.status === 'CONFIRMED';
    const start = appointmentData ? moment(appointmentData.estimated_start_at) : moment();
    const isEndTimeError = appointmentData ? appointmentData.time_start >= appointmentData.time_end : false;
    const confirmIsDisable = !isEditable || isEndTimeError || (!appointmentData?.is_lock && appointmentData?.is_manual && !appointmentData.description);

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Indirizzo copiato negli appunti!');
        }, (err) => {
            console.error('Errore durante la copia: ', err);
        });
    }

    return (<>
        <Offcanvas
            placement="bottom"
            className="action-sheet placeholder-glow"
            show={!!appointmentId}
            onHide={handleClose}
        >
            <Offcanvas.Body>
                <div
                    style={{
                        ...appointmentData?.is_manual ? {
                            backgroundImage: 'none',
                            backgroundColor: '#292929',
                            borderBottom: 0,
                            height: '50px'
                        } : {
                            ...headerImage && { backgroundImage: `url(${headerImage})` }
                        }
                    }}
                    className="property-card-header small"
                >

                    {(isEditable && canEdit && (user.is_admin || user.is_agent)) && (
                        <IoTrashOutline className="delete-btn" style={{ left: '16px' }} onClick={() => dispatchConfirmDelete()} />
                    )}

                    <a className="offcanvas-close" onClick={handleClose}>
                        <IoCloseOutline />
                    </a>

                    {(isEditable && user.is_agent && canEdit) && (<span
                        className={`add-prenotation-btn bg-primary`}
                        onClick={() => navigate(`/reservation/${appointmentId}/edit`)}
                    >Modifica</span>
                    )}

                </div>
                <div className="action-sheet-content py-2 px-0 noarrow">

                    {!appointmentData?.is_manual && <div className="listview-title pb-0">
                        <h3 className='mb-0'>
                            {appointmentData ? appointmentData.order.organization.display_name : <span className="placeholder" style={{ width: '250px' }} />}
                            {appointmentData?.status === 'CANCELED' && <span className="badge bg-danger ms-1">ANNULLATO</span>}
                            {appointmentData?.status === 'ENDED' && <span className="badge bg-success ms-1">TERMINATO</span>}
                        </h3>
                    </div>}

                    <ul className="listview image-listview no-border">

                        {appointmentData?.is_manual && <li>
                            <div className='section'>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="form-group boxed custom pb-0">
                                            <div className="input-wrapper">
                                                <label className="label-bordered form-label">Descrizione</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    style={{ background: '#3a3a3a' }}
                                                    name="description"
                                                    value={capitalizeFirstLetter(appointmentData.description || (appointmentData.is_lock ? 'Slot disabilitato' : ''))}
                                                    onChange={handleChange}
                                                    disabled={!isEditable}
                                                    onClick={() => {appointmentData.is_lock && handleUnlockAppointment()}}
                                                    onKeyUp={() => {appointmentData.is_lock && handleUnlockAppointment()}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>}

                        {!user.is_admin && (
                            <li>
                                <div className="item">
                                    <div className="in">
                                        <div>
                                            <header><IoTime className="vertical-align-n2" /> Data e ora</header>
                                            {appointmentData ? capitalizeFirstLetter(
                                                start.format('dddd D MMMM YYYY - HH:mm')
                                            ) : <span className="placeholder" style={{ width: '100px' }} />}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                        {user.is_admin && (
                            <>
                                <li>
                                    <div className='section pb-1 mt-1'>
                                        <header><IoTime className="vertical-align-n2" /> Data e ora</header>
                                        <div className="row g-3">
                                            <div className="col-sm-6 col-12">
                                                <div className="form-group boxed custom pb-0">
                                                    <div className="input-wrapper">
                                                        <label className="label-bordered form-label">Data</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            style={{ background: '#3a3a3a' }}
                                                            name="date"
                                                            value={start.format('YYYY-MM-DD')}
                                                            onChange={handleChange}
                                                            disabled={!isEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-6">
                                                <div className="form-group boxed custom pb-0">
                                                    <div className="input-wrapper">
                                                        <label className="label-bordered form-label">Ora inizio</label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            style={{ background: '#3a3a3a' }}
                                                            name="start"
                                                            value={appointmentData?.time_start || ''}
                                                            onChange={handleChange}
                                                            disabled={!isEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-6">
                                                <div className="form-group boxed custom pb-0">
                                                    <div className="input-wrapper">
                                                        <label className="label-bordered form-label">Ora fine</label>
                                                        <input
                                                            type="time"
                                                            className={`form-control ${isEndTimeError ? 'is-invalid' : ''}`}
                                                            style={{ background: '#3a3a3a' }}
                                                            name="end"
                                                            value={appointmentData?.time_end || ''}
                                                            onChange={handleChange}
                                                            disabled={!isEditable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {appointmentData && appointmentData.is_manual && !appointmentData.is_lock && <li>
                                    <div className='section pb-1 mt-1'>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <div className="form-group boxed custom pb-0">
                                                    <AutocompleteAddress
                                                        label="Indirizzo"
                                                        value={address}
                                                        setValue={setAddress}
                                                        handleRetrive={handleRetriveAddress}
                                                        handleReset={handleResetAddress}
                                                        defaultLockValue={appointmentData ? !!appointmentData.coords : false}
                                                        withGoogle
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>}
                            </>
                        )}
                        {!appointmentData?.is_manual && <>
                            <li>
                                <div className="item">
                                    <div className="in">
                                        <div className='flex-fill'>
                                            {appointmentData ? (
                                                <>
                                                    <header><IoHome className="vertical-align-n2" /> Indirizzo appuntamento:</header>
                                                    <IoCopy className='cursor-pointer vertical-align-n2 ms-05 float-end' title="Copia indirizzo" onClick={() => copyToClipboard(appointmentData.building.address.full)}/>
                                                    <a 
                                                        className="active cursor-pointer"
                                                        target="_blank"
                                                        href={addressUrl(appointmentData.building.coords)}
                                                    >
                                                        {appointmentData.building.address.full}
                                                    </a>
                                                    <br />
                                                    {`Rif. ${appointmentData.building.reference} - `}
                                                    <span className="fw-600">Proprietario:</span>{' '}
                                                    {appointmentData.building.owner_last_name} {appointmentData.building.owner_first_name}
                                                    <br />
                                                    {`${appointmentData.building.building_type.description} - `}
                                                    <IoExpandOutline className='vertical-align-n2' /> {appointmentData.building.sqm} m² -{' '}
                                                    <IoLeafOutline className='vertical-align-n2' /> {appointmentData.building.garden_sqm} m²{' '}
                                                    {appointmentData.building.floor && <> - Piano {appointmentData.building.floor}</>}
                                                </>
                                            ) : (
                                                <>
                                                    <header><IoHome className="vertical-align-n2" /> Indirizzo appuntamento:</header>
                                                    <span className="placeholder" style={{ width: '250px' }} />
                                                    <br />
                                                    <span className="placeholder" style={{ width: '200px' }} />
                                                    <br />
                                                    <span className="placeholder" style={{ width: '220px' }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <div className="in">
                                        <div>
                                            <header><IoPerson className="vertical-align-n2" /> Riferimenti:</header>
                                            <span className="fw-600">Abitato da: </span>
                                            {appointmentData ? (
                                                <>
                                                    {appointmentData.building.resident_type === 'NOBODY' ? 'Nessuno' : (
                                                        <>
                                                            {appointmentData.building.resident_type === 'OWNER' ? 'Proprietario' : 'Inquilino'}
                                                            <br />
                                                            {appointmentData.building.doorbell_name}
                                                        </>
                                                    )}
                                                </>) : (
                                                <>
                                                    <span className="placeholder" style={{ width: '80px' }} />
                                                    <br />
                                                    <span className="placeholder" style={{ width: '100px' }} />
                                                </>
                                            )}
                                        </div>
                                        {appointmentData?.building.resident_type !== 'NOBODY' && (
                                            <div>
                                                <a href={`mailto:${appointmentData?.building.resident_email}`}>
                                                    <button type="button" className={`btn btn-sm px-2 btn-call btn-linkedin me-05 ${appointmentData ? '' : 'placeholder'}`}>
                                                        <IoMail className="text-14" /> Email
                                                    </button>
                                                </a>
                                                <a href={`tel:${appointmentData?.building.resident_phone}`}>
                                                    <button type="button" className={`btn btn-success btn-sm px-2 btn-call ${appointmentData ? '' : 'placeholder'}`}>
                                                        <IoCall className="text-14" /> Chiama
                                                    </button>
                                                </a>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </li>
                            {user.is_admin && appointmentData && appointmentData.agent && <>
                                <li>
                                    <div className="item">
                                        <div className="in">
                                            <div>
                                                <header><IoPerson className="vertical-align-n2" /> Agente:</header>
                                                {appointmentData.agent.user.display_name}
                                            </div>
                                            <div>
                                                <header><IoPerson className="vertical-align-n2" /> Sul posto:</header>
                                                {appointmentData.agent_on_site ? 'Si' : 'No'}
                                            </div>
                                            <div>
                                                <a href={`tel:${appointmentData.agent.user.phone}`}>
                                                    <button type="button" className={`btn btn-success btn-sm px-2 btn-call ${appointmentData ? '' : 'placeholder'}`}>
                                                        <IoCall className="text-14" /> Chiama
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </>}
                            <li>
                                <div className="item">

                                    <div className="in">
                                        <div>
                                            <header><IoCamera className="vertical-align-n2" /> Servizi</header>
                                            {appointmentData ? <>
                                            {appointmentData?.order?.order_items.map(item => (
                                                <Fragment key={item.id}>
                                                    {item.product.description} {item.is_priority ? <span className="text-warning">({item.product.priority_label})</span> : ''} {item.poll.is_done === false ? <u className="text-danger">NON SVOLTO</u> : ''}
                                                    <br />
                                                </Fragment>
                                            ))}
                                            {appointmentData?.order?.travel_paid > 0 && <>Trasferta ({appointmentData.order.travel_paid.toFixed(2)}€ + IVA)<br /></>}
                                            </> : <span className="placeholder" style={{ width: '150px' }} />}
                                            {/* <span className="text-14 ms-1 text-danger">
                                                (<IoWarning className='vertical-align-n2'/> Non svolto causa maltempo)
                                            </span> */}
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <div className="in">
                                        <div>
                                            <header><IoHourglass className="vertical-align-n2" /> Durata totale</header>
                                            {appointmentData ? `${appointmentData.duration} minuti`
                                                : <span className="placeholder" style={{ width: '80px' }} />}
                                        </div>
                                        {(user.is_admin || user.is_agent) && <>
                                            <div>
                                                <header><IoCash className="vertical-align-n2" /> Totale</header>
                                                {appointmentData ? `${appointmentData.order.final_price} € + IVA`
                                                    : <span className="placeholder" style={{ width: '78px' }} />}
                                                {/* <span className="ms-3 active">
                                                <IoOpenOutline className='vertical-align-n2'/>
                                                Visualizza fattura
                                            </span> */}
                                            </div>
                                            <div>
                                                <header><IoInformationCircle className="vertical-align-n2" /> Metodo</header>
                                                {appointmentData?.order?.payment_method && PAYMENT_METHODS[appointmentData?.order?.payment_method]}
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </li>
                        </>}
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div className='flex-fill'>
                                        <header><IoPencil className="vertical-align-n2" /> Note appuntamento:</header>
                                        {(user.is_admin || user.is_agent) ? (
                                            <div className="form-group boxed">
                                                <textarea
                                                    rows="2"
                                                    className="form-control"
                                                    name="note"
                                                    value={appointmentData?.note || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ) : (
                                            appointmentData?.note
                                        )}
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    {(user.is_admin || (user.is_agent && moment(appointmentData?.estimated_start_at).isSame(moment(), 'day'))) && (
                        <>
                            <hr className="mt-0" />

                            <div className="listview-title fw-700 py-0">
                                Fotografo assegnato:
                            </div>

                            <div className="section my-2">
                                <div className="profile-head">
                                    <div className="avatar">
                                        <img src={appointmentData?.photographer.user.avatar_url || defaultAvatar} width="96" height="96" className={`imaged rounded w48 ${appointmentData ? '' : 'placeholder'}`} />
                                    </div>
                                    <div className="in">
                                        <h3 className="name text-14">
                                            {appointmentData ? appointmentData?.photographer.user.display_name
                                                : <span className="placeholder" style={{ width: '100px' }} />}
                                        </h3>
                                        {appointmentData ?
                                            <a href={`tel:${appointmentData?.photographer.user.phone}`}>
                                                <button type="button" className="btn btn-success btn-sm px-2 btn-call">
                                                    <IoCall className="text-14" />
                                                    Chiama
                                                </button>
                                            </a>
                                            :
                                            <span className="placeholder" style={{ width: '100px' }} />
                                        }
                                    </div>

                                    {(user.is_admin && photographers.length > 0 &&
                                        <div className='flex-fill' style={{ textAlign: 'right' }}>
                                            <div className="form-group boxed custom py-0">
                                                <div className="input-wrapper">
                                                    <select
                                                        className="form-control form-select d-inline w-auto ms-1"
                                                        style={{ background: '#3a3a3a' }}
                                                        disabled={!isEditable}
                                                        name="photographer"
                                                        onChange={handleChange}
                                                        value={appointmentData?.photographer_id || ''}
                                                    >
                                                        {photographers.map(ph => (
                                                            <option key={ph.id} value={ph.id}>{ph.title}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {/*<ul className="listview image-listview no-border">
                        <li>
                            <div className="item">

                                <div className="in">
                                    <div>
                                        <header>Materiale</header>Consegnato il DD/MM/YYYY / In lavorazione
                                    </div>

                                </div>
                            </div>
                        </li>
                    </ul> */}

                    {(user.is_admin) && (
                        <div className="section mt-3 mb-1">
                            <Button
                                onClick={handleUpdate}
                                className={`btn-block btn-primary ${(confirmIsDisable) ? 'disabled' : (needSave ? 'blinking-element' : '')}`}
                            >Conferma</Button>
                        </div>
                    )}

                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default OffcanvasViewAppointment;
//Layout
import Header from 'layout/header/Header';
import Capsule from 'layout/Capsule';

//Components
import OffcanvasAddBuilding from 'components/offcanvas/add-building/OffcanvasAddBuilding';
import Agenda from './photographer/Agenda';
import OffCanvasAddFunds from 'components/offcanvas/OffCanvasAddFunds.js';
import OffCanvasHistoryFunds from 'components/offcanvas/OffCanvasHistoryFunds.js';
import { Link } from 'react-router-dom';
import ModalRequestNotifications from 'components/ModalRequestNotifications.js';
import PostProduction from 'pages/post-production/PostProduction.js';
//Agent Components
import Intro from './agent/Intro.js';
import ServiceCards from './agent/ServiceCards.js';
import BalanceCard from './agent/BalanceCard.js';
import NextAppointments from './agent/NextAppointments';
import ServiceList from './agent/ServicesList.js';
//Admin Components
import NextAppointmentsAdmin from './admin/NextAppointmentsAdmin.js';
import PostProductionStatAdmin from './admin/PostProductionStatAdmin';
import StatCards from './admin/StatCards';
import LastOrdersAdmin from './admin/LastOrdersAdmin';
//Logic
import { useHome, useHomeAgent } from './useHome';
import { useNavigate } from 'react-router';
import SocialPlanning from 'pages/social-planning/SocialPlanning';
import { CardGraph } from 'components/graphs/CardGraph';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { formatterEuro, isMobile } from 'utils/helpers';
import Slider from 'react-slick';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { AreaChart } from '@saas-ui/charts';
import { SaasProvider } from '@saas-ui/react'
import StatsDashboard from 'pages/stats/StatsDashboard';
import GoalGraph from 'components/graphs/GoalGraph';


const SliderIfMobile = ({ children }) => {

    if (!isMobile()) return children;

    return <div style={{ 
        height: '115px',
        width: '300px',
        flex: '1 1 auto',
    }} className='stats-slide-card'>
        <Slider 
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            variableWidth={false}
            arrows={false}
            dots={true}
            className='home-stats-slider'
        >
            {children}
        </Slider>
    </div>
}

function HomeStaff(props) {

    const [statsData, setStatsData] = useState();
    const [filterMonth, setFilterMonth] = useState(0);

    useEffect(() => {
        setStatsData();
        axios.get('/stats/home', {
            params: { filterMonth }
        })
        .then(res => {
            setStatsData(res.data)
        });
    }, [filterMonth]);

    const months = useMemo(() => {
        const currentMonthLabel = new Date().toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + new Date().toLocaleString('default', { month: 'long' }).slice(1);
        const lastMonthLabel = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { month: 'long' }).slice(1);
        const twoLastMonthLabel = new Date(new Date().setMonth(new Date().getMonth() - 2)).toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + new Date(new Date().setMonth(new Date().getMonth() - 2)).toLocaleString('default', { month: 'long' }).slice(1);
        return [twoLastMonthLabel, lastMonthLabel, currentMonthLabel];
    }, []);

    const changeFilterMonth = (month) => {
        setFilterMonth(month);
    }

    return (
        <>
            <Header
                show_logo
                showNotifications
            />
            <Capsule className='pb-5'>
                <div className='section'>
                    <div className='d-flex justify-content-center gap-2'>
                        <Button variant={filterMonth === -2 ? 'primary' : 'dark'} onClick={() => changeFilterMonth(-2)}>{months[0]}</Button>
                        <Button variant={filterMonth === -1 ? 'primary' : 'dark'} onClick={() => changeFilterMonth(-1)}>{months[1]}</Button>
                        <Button variant={filterMonth === 0 ? 'primary' : 'dark'} onClick={() => changeFilterMonth(0)}>{months[2]}</Button>
                    </div>
                </div>
                <SaasProvider>
                    <div className="d-flex gap-2 flex-wrap placeholder-glow mt-3 stats-home-container">
                        <SliderIfMobile>
                            <CardGraph 
                                label={'Totale prodotto'}
                                um='€'
                                value={statsData?.total_product_sales}
                            />
                            <CardGraph 
                                label={'Totale fatturato'}
                                um='€'
                                value={statsData?.total_sales}
                            />
                            <CardGraph
                                label={'Credito clienti'}
                                um='€'
                                value={statsData?.organization_credits}
                            />
                        </SliderIfMobile>
                        <SliderIfMobile>
                            <CardGraph 
                                label={'Servizi'}
                                value={statsData?.total_services}
                                round={0}
                            />
                            <CardGraph 
                                label={'Servizi in 24h'}
                                value={statsData?.total_priority}
                                round={0}
                            />
                        </SliderIfMobile>
                        <CardGraph 
                            label={'Prezzo medio servizio fotografico'}
                            um='€'
                            value={statsData?.average_order}
                        />
                        <CardGraph 
                            label={'Metratura immobili'}
                            um='mt²'
                            value={statsData?.average_sqm}
                        />
                    </div>
                    <div className='section placeholder-glow mt-3 stats'>
                        <Card className={`w-100 ${statsData?.goals ? '' : 'placeholder'}`}>
                            <Card.Body className="py-3">
                                <h3>Goals</h3>
                                <div>
                                    <div className="d-flex gap-3">
                                        <GoalGraph
                                            value={statsData?.goals.PHOTO[0]}
                                            goal={statsData?.goals.PHOTO[1]}
                                            label="Foto"
                                        />
                                        <GoalGraph
                                            value={statsData?.goals.VIDEO[0]}
                                            goal={statsData?.goals.VIDEO[1]}
                                            label="Video"
                                        />
                                        <GoalGraph
                                            value={statsData?.goals.DRONE[0]}
                                            goal={statsData?.goals.DRONE[1]}
                                            label="Drone"
                                        />
                                        <GoalGraph
                                            value={statsData?.goals.MATTER[0]}
                                            goal={statsData?.goals.MATTER[1]}
                                            label="Matter"
                                        />
                                    </div>
                                    <div className="d-flex gap-3 mt-2 fw-600">
                                        <div className="text-center flex-1">Foto</div>
                                        <div className="text-center flex-1">Video</div>
                                        <div className="text-center flex-1">Drone</div>
                                        <div className="text-center flex-1">Matter</div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="section placeholder-glow mt-3 stats">
                        <Card className={`w-100 ${statsData?.sales ? '' : 'placeholder'}`}>
                            <Card.Body className="px-0 py-3">
                                <div className="section">
                                    <h3>Fatturato annuale</h3>
                                </div>
                                <AreaChart
                                    showYAxis={false}
                                    data={statsData?.sales}
                                    categories={['2023', '2024']}
                                    height="300px"
                                    colors={['#7D7F82', '#135CFA']}
                                    valueFormatter={formatterEuro}
                                    showGrid={false}
                                    legendHeight={1}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="section placeholder-glow mt-3 stats">
                        <Card  className={`w-100 ${statsData?.sales ? '' : 'placeholder'}`}>
                            <Card.Body className="px-0 py-3">
                                <div className="section">
                                    <h3>Andamento servizi</h3>
                                </div>
                                <AreaChart
                                    showYAxis={false}
                                    data={statsData?.trend}
                                    categories={['Servizi', 'Ordini']}
                                    height="300px"
                                    colors={['#7D7F82', '#135CFA']}
                                    valueFormatter={val => val.toLocaleString('it-IT')}
                                    showGrid={false}
                                    legendHeight={1}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </SaasProvider>
                {/* <NextAppointmentsAdmin />
                <PostProductionStatAdmin />
                <LastOrdersAdmin />
                <StatCards/> */}
            </Capsule>
        </>
    );
}

function HomeEditor() {
    return (
        <>
            <Header
                show_logo
                showNotifications
            />
            <PostProduction pageRole="editor" />
        </>
    );
}

export function HomePhotographer() {
    return (
        <>
            <Header
                show_logo
                showNotifications
            />
            <Capsule>
                <Agenda />
            </Capsule>
        </>
    );
}

export function HomeAgent(props) {

    const {
        showOffCanvasAddBuilding, setShowOffCanvasAddBuilding,
        showOffCanvasAddFunds, dispatchShowOffCanvasAddFunds,
        showOffCanvasHistoryFunds, dispatchShowOffCanvasHistoryFunds
    } = useHomeAgent();

    return (
        <>
            <Header switch_agency showNotifications />
            <Capsule className='pb-5'>
                <Intro handleAddBuilding={() => setShowOffCanvasAddBuilding(true)} />
                <BalanceCard
                    dispatchShowOffCanvasAddFunds={dispatchShowOffCanvasAddFunds}
                    dispatchShowOffCanvasHistoryFunds={dispatchShowOffCanvasHistoryFunds}
                />
                <NextAppointments />
                <hr className="mt-2 line-divider" />
                <ServiceCards />
                <hr className="mt-2 line-divider" />
                <ServiceList />
                <hr className="mt-1 line-divider" />

                <div className='section mt-4'>
                    <h2>Hai bisogno di supporto?</h2>
                    Scrivici su <b><Link to="https://api.whatsapp.com/send?phone=393887551924" className='text-success' target="_blank">Whatsapp</Link></b> per feedback, segnalazioni o problemi. Ti risponderemo il prima possibile.
                </div>
                <Link id="btn-whatsapp" className='whatsapp' to="https://api.whatsapp.com/send?phone=393887551924" target="_blank"></Link>
            </Capsule>
            <OffcanvasAddBuilding
                show={showOffCanvasAddBuilding}
                handleClose={() => setShowOffCanvasAddBuilding(false)}
                isReservation
            />
            <OffCanvasAddFunds
                show={showOffCanvasAddFunds}
                handleClose={() => dispatchShowOffCanvasAddFunds('CLOSE')}
            />
            <OffCanvasHistoryFunds
                show={showOffCanvasHistoryFunds}
                handleClose={() => dispatchShowOffCanvasHistoryFunds('CLOSE')}
            />
        </>
    );
}

Home.Photographer = HomePhotographer;
Home.Editor = HomeEditor;

function Home() {

    const {
        user,
        showRequestNotifications,
        dispatchShowRequestNotifications
    } = useHome();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        if (user.is_agent) {
            if (!user.agent.organizations.length) return navigate('welcome-agent');
        }
        if (user.is_accountant && user.is_admin) {
            return navigate('/admin/calendar');
        }
    }, [user]);

    if (!user) return;

    return (<>
        {user.is_agent ? <HomeAgent /> : (
            user.is_admin ? <HomeStaff /> : (
                user.is_photographer ? <HomePhotographer /> : (
                    user.is_editor ? <HomeEditor /> : (
                        user.is_social_manager ? <SocialPlanning /> : (
                            user.is_accountant ? <StatsDashboard /> : ''
                        )
                    )
                )
            )
        )}
        {showRequestNotifications && <ModalRequestNotifications
            show={showRequestNotifications}
            handleClose={() => dispatchShowRequestNotifications('CLOSE')}
        />}
    </>)
}

export default Home;
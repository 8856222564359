import { IoSearchOutline } from "react-icons/io5";

function Intro(props) {

    const { handleAddBuilding } = props;

    return (<>
        <div id="home">
            <div className="section text-center" id="home-intro">
                <h1>Prenota un appuntamento</h1>
                <p>Facci sapere dove si trova l'immobile e ti daremo le migliori disponibilità</p>
            </div>
            <div className="section pb-2 text-center" id="home-input">
                <div className="form-group boxed py-4 px-3">
                    <div className="input-wrapper not-empty max-width-600 m-auto">
                        <IoSearchOutline className="input-icon-search" />
                        <input
                            type="text"
                            className="form-control cursor-pointer"
                            placeholder="Inserisci indirizzo immobile..."
                            autoComplete="off"
                            onClick={handleAddBuilding}
                            onFocus={e => e.target.blur()}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>);

}

export default Intro;
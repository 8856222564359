import axios from "axios";
import AutocompleteAddress from "components/autocomplete/AutocompleteAddress";
import SpinnerForButton from "components/loader/SpinnerForButton";
import Capsule from "layout/Capsule";
import Header from "layout/header/Header";
import { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IoNavigateOutline, IoPinOutline } from "react-icons/io5";

export default function CalculateTransfer() {
    
    const [results, setResults] = useState(null);
    const [coordsFrom, setCoordsFrom] = useState([10.9212827, 44.6600919]);
    const [coordsTo, setCoordsTo] = useState(null);
    const [addressFrom, setAddressFrom] = useState('Via Marcantonio Parenti, 47/c, 41122 Modena MO');
    const [addressTo, setAddressTo] = useState('');
    const [isCalculating, setIsCalculating] = useState(false);

    const handleCalculateTransfer = () => {
        setIsCalculating(true);
        axios.get(process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + 'calendar-calculatetolls', {
            params: {
                start_point: coordsFrom.join(','),
                end_point: coordsTo.join(',')
            }
        })
        .then(res => {
            setResults({
                toll: (res.data.toll ?? 0) * 2,
                km_refound: ((Math.round(0.4 * (res.data.length ?? 0) / 1000, 2) ?? 0) * 2)
            });
            setIsCalculating(false);
        })
    };

    const handleRetriveFrom = useCallback((res) => {
        setCoordsFrom([res.coords.lng, res.coords.lat]);
        setAddressFrom(res.full_address);
    }, []);

    const handleRetriveTo = useCallback((res) => {
        setCoordsTo([res.coords.lng, res.coords.lat]);
        setAddressTo(res.full_address);
    }, []);

    useEffect(() => {
        setResults(null);
    }, [addressFrom, addressTo]);

    return (
        <>
            <Header
                show_agency={false}
                pageTitle="Calcolo trasferte" 
            />
            <Capsule>
                <div className='section'>
                    <Form>
                        <div className="wide-block pb-1 pt-1 rounded mb-2">
                            <ul className="listview image-listview no-line no-space flush">
                                <li>
                                    <div className="item">
                                        <div className="icon-box bg-primary">
                                            <IoNavigateOutline />
                                        </div>
                                        <div className="in">
                                            <div className="form-group boxed custom">
                                                <AutocompleteAddress
                                                    withGoogle 
                                                    label="Indirizzo di partenza*"
                                                    value={addressFrom}
                                                    setValue={setAddressFrom}
                                                    handleRetrive={handleRetriveFrom}
                                                    defaultLockValue
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item">
                                        <div className="icon-box bg-primary">
                                            <IoPinOutline />
                                        </div>
                                        <div className="in">
                                            <div className="form-group boxed custom">
                                                <AutocompleteAddress
                                                    withGoogle 
                                                    label="Indirizzo di destinazione*"
                                                    value={addressTo}
                                                    setValue={setAddressTo}
                                                    handleRetrive={handleRetriveTo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <Button
                                className={`btn-block btn-primary ${(isCalculating || !coordsFrom?.length || !coordsTo?.length) ? 'disabled' : ''}`}
                                type="button"
                                onClick={handleCalculateTransfer}
                            >
                                <SpinnerForButton show={isCalculating} /> 
                                Calcola
                            </Button>
                        </div>
                    </Form>
                </div>
                {results && <div className='section'>
                    <div className="card">
                        <div className="card-body">
                            <h3 className="mb-0">Risultati (A/R)</h3>
                            <div className="mt-2">
                                <p className="mb-1 text-light">Pedaggio: <b>€ {results.toll.toFixed(2)}</b></p>
                                <p className="mb-1 text-light">Rimborso km: <b>€ {results.km_refound.toFixed(2)}</b></p>
                                <p className="mb-1 text-light">Totale: <b>€ {results.toll + results.km_refound.toFixed(2)}</b></p>
                            </div>
                        </div>
                    </div>
                </div>}
            </Capsule>
        </>
    );
}